import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService {
    constructor(private loadingSpinnerService: LoadingSpinnerService) { }

    getURLPath = (urlString: string) => {
        const tempURL = new URL(urlString);
        return tempURL.pathname.toLowerCase().replace(/^\/|\/$/gm, '');
    };

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers: HttpHeaders;
        const urlPath = this.getURLPath(req.url);

        if (urlPath === 'auth/update-profile') {
            headers = new HttpHeaders({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            });
        } else if ((req.headers.has('fileUpload'))) {
            req = req.clone({
                headers: req.headers.delete('fileUpload')
            });
            req = req.clone({
                headers: req.headers.set('Accept', '*/*')
            });
            req = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            });
        } else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            });
        }
        const clone = req.clone({
            headers,
        });

        // start the spinner
        this.loadingSpinnerService.setLoading(true, req.url);

        return next.handle(clone).pipe(
            map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    // stop the spinner upon completion of an http event.
                    this.loadingSpinnerService.setLoading(false, req.url);
                }
                return evt;
            }),
            catchError((error) => {
                // stop the spinner upon error.
                this.loadingSpinnerService.setLoading(false, req.url);
                return throwError(error);
            })
        );
    }
}
