import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '../../common/common.service';
import * as apiConstants from 'src/utils/api-constants';
import { HttpParams } from '@angular/common/http';
import { SubmitIcResponse } from 'src/app/interfaces/member/submit-ic-response.interface';
import { NewMemberToRegister } from 'src/app/interfaces/member/first-time-registration.interface';
import { Router } from '@angular/router';
import { Login, LoginResponse } from 'src/app/interfaces/auth/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private common: CommonService, private router: Router) {}

  submitIc(ic: number): Observable<SubmitIcResponse> {
    const params = new HttpParams().set('ic', ic.toString());
    return this.common.reactiveGet(apiConstants.MEMBER.INPUT_IC, params);
  }

  submitOtp(member: { ic: string; otp: string }): Observable<LoginResponse> {
    return this.common.reactivePost(apiConstants.MEMBER.LOGIN_WITH_OTP, member);
  }

  fetchMobileNoToSendOtp(
    ic: string
  ): Observable<{ message: string; maskedMobileNumber: string }> {
    return this.common.reactivePost(apiConstants.MEMBER.FIRST_TIME_SEND_OTP, {
      ic,
    });
  }

  checkIfMobileNoAlreadyExists(
    mobileNumber: number
  ): Observable<{ isExists: boolean }> {
    const params = new HttpParams().set('mobile', mobileNumber.toString());
    return this.common.reactiveGet(
      apiConstants.MEMBER.CHECK_IF_MOBILE_NO_ALREADY_EXISTS,
      params
    );
  }

  sendOtpToRegisterNewMobileNo(mobileNumber: string) {
    return this.common.reactivePost(apiConstants.MEMBER.FIRST_TIME_SEND_OTP, {
      mobileNumber,
    });
  }

  submitOtpToRegisterNewMobileNo(
    newMember: NewMemberToRegister
  ): Observable<LoginResponse> {
    return this.common.reactivePost(
      apiConstants.MEMBER.FIRST_TIME_REGISTER,
      newMember
    );
  }

  login(body: Login): Observable<LoginResponse> {
    return this.common.reactivePost(apiConstants.AUTH.LOGIN, body);
  }

  isLoggedin(): boolean {
    if (localStorage.getItem('token')) {
      if (!localStorage.getItem('pkrUser')) {
        localStorage.clear();
        return false;
      }
      return true;
    }
    return false;
  }

  logout(): void {
    localStorage.clear();
    this.common.showSnackbar(
      "you've been logged out successfully!",
      null,
      3000
    );
    this.router.navigate(['/login']);
  }

  registerMobileNoWithIc(member: {
    ic: string;
    mobileNumber: string;
    otp: string;
  }) {
    return this.common.reactivePost(
      apiConstants.MEMBER.REGISTER_MOBILE_NUMBER,
      member
    );
  }
}
