import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Candidate } from 'src/app/interfaces/candidate/candidate.interface';
import { environment } from 'src/environments/environment';
import * as apiConstants from 'src/utils/api-constants';
import * as appConstant from '../../../utils/app-constants';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    isMobile: boolean = false;

    constructor(private http: HttpClient, private snackbar: MatSnackBar) {
        this.isMobile = window.matchMedia(
            'only screen and (max-width: 768px)'
        ).matches;
    }

    reactiveGet(endPoint: string, params?: HttpParams): Observable<any> {
        return this.http
            .get<any>(environment.apiBaseUrl + endPoint, { params: params })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactivePost(endPoint: string, body, params?: HttpParams): Observable<any> {
        return this.http
            .post<any>(environment.apiBaseUrl + endPoint, body, { params: params })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactiveBlob(endPoint: string, body, params?: HttpParams): Observable<any> {
        return this.http
            .post(environment.apiBaseUrl + endPoint, body, { params: params, responseType: 'blob' })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactiveBlobGet(endPoint: string, body, params?: HttpParams): Observable<any> {
        return this.http
            .get(environment.apiBaseUrl + endPoint, { params: params, responseType: 'blob' })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactivePostUploadMedia(endPoint: string, body: FormData): Observable<any> {
        return this.http
            .post<any>(environment.apiBaseUrl + endPoint, body, { headers: { 'fileUpload': 'true' } })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactivePut(endPoint: string, body, params?: HttpParams): Observable<any> {
        return this.http
            .put<any>(environment.apiBaseUrl + endPoint, body, { params: params })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactivePatch(endPoint: string, body, params?: HttpParams): Observable<any> {
        return this.http
            .patch<any>(environment.apiBaseUrl + endPoint, body, { params: params })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    reactiveDelete(endPoint: string, params?: HttpParams): Observable<any> {
        return this.http
            .delete<any>(environment.apiBaseUrl + endPoint, { params: params })
            .pipe(timeout(appConstant.API_TIMEOUT), catchError(this.handleError));
    }

    private handleError = (err: any): Observable<never> => {
        console.log('err - ', err);
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}`;
        }
        this.showSnackbar(err.error.message, null, appConstant.MESSAGE_READ_TIME);
        return throwError(errorMessage);
    };

    showSnackbar(message, action = null, duration: number = appConstant.MESSAGE_READ_TIME) {
        this.snackbar.open(message, action, {
            duration: duration,
            panelClass: ['snackbar'],
        });
    }

    getImageUrl(id: string): string | null {
        if (id != undefined && id.trim().length != 0) {
            return this.formatString(environment.apiBaseUrl + apiConstants.FILE.DOWNLOAD_FILE, id.trim());
        } else {
            return null;
        }
    }

    formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    shareAbleData = new BehaviorSubject({});

    paginate(
        totalItems: number,
        currentPage: number,
        pageSizeLimit: number,
        maxPages: number = this.isMobile ? 3 : 10
    ) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSizeLimit);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSizeLimit;
        let endIndex = Math.min(startIndex + pageSizeLimit - 1, totalItems - 1);

        // create an array of pages to ng-for in the pager control
        let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
            (i) => startPage + i
        );

        // Offset calculation
        let offset = (currentPage - 1) * pageSizeLimit;
        // return object with all page properties required by the view

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSizeLimit: pageSizeLimit,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
            offset: offset,
        };
    }

    getAddress(data: Candidate) {
        return (
            data.address1 +
            ', ' +
            data.address2 +
            ', ' +
            data.address3 +
            ', ' +
            data.city +
            ', '
        );
    }

    getRemainingDays(votingEnd) {
        // current date
        let start = moment().format('YYYY-MM-DD');
        let end = moment(votingEnd, 'YYYY-MM-DD');

        // //Difference in number of days
        return end.diff(start, 'days');
    }

    getNominationStatus(nominationStart, nominationEnd): string {
        let today = moment();
        let startDate = moment(nominationStart, 'YYYY-MM-DD');
        let endDate = moment(nominationEnd, 'YYYY-MM-DD');

        let isNominationRunning = today.isBetween(startDate, endDate);
        return isNominationRunning ? 'Nomination Running' : '--';
    }

    getVotingStatus(votingStart, votingEnd) {
        let today = moment();
        let startDate = moment(votingStart, 'YYYY-MM-DD');
        let endDate = moment(votingEnd, 'YYYY-MM-DD');

        let isVotingRunning = today.isBetween(startDate, endDate);
        return isVotingRunning ? 'On Going' : '--';
    }

    commonGetServiceForDownload(
        endPoint: string,
        params?: HttpParams
    ): Observable<any> {
        return this.http
            .get(environment.apiBaseUrl + endPoint, {
                params,
                responseType: 'arraybuffer',
            })
            .pipe(timeout(60000), catchError(this.handleError));
    }

    commonGetServiceForDownloadExcel(
        endPoint: string,
        params?: HttpParams
    ): Observable<any> {
        return this.http
            .get(environment.apiBaseUrl + endPoint, {
                params,
                responseType: 'arraybuffer',
            })
            .pipe(timeout(60000), catchError(this.handleError));
    }

    getAllStates(): Observable<any> {
        return this.http
            .get(environment.apiBaseUrl + apiConstants.UTILITIES.GET_ALL_STATES).pipe(timeout(60000), catchError(this.handleError));
    }
    getAllDivisions(): Observable<any> {
        return this.http
            .get(environment.apiBaseUrl + apiConstants.UTILITIES.GET_ALL_DIVISIONS).pipe(timeout(60000), catchError(this.handleError));
    }
}
