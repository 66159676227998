import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SideNavListComponent } from './side-nav-list/side-nav-list.component';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { RefreshComponent } from './refresh/refresh.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonPopupComponent } from './common-popup/common-popup.component';
import { CommonActionPopupComponent } from './common-action-popup/common-action-popup.component';
import { NgMultiselectDropdownComponent } from './ng-select-dropdown/ng-multiselect-dropdown/ng-multiselect-dropdown.component';
import { NgSelectDropdownComponent } from './ng-select-dropdown/ng-select-dropdown/ng-select-dropdown.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    HeaderComponent,
    SideNavListComponent,
    RefreshComponent,
    CommonPopupComponent,
    CommonActionPopupComponent,
    NgSelectDropdownComponent,
    NgMultiselectDropdownComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    SideNavListComponent,
    MaterialModule,
    RefreshComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonPopupComponent,
    NgSelectDropdownComponent,
    NgMultiselectDropdownComponent
  ],
})
export class SharedModule { }
