export enum Role {
  SuperAdmin = 'SuperAdmin',
  HQ = 'HQ',
  HQUser = 'HQUser',
  User = 'User',
  Committee = 'Committee',
  Editors = 'Editors',
  Reporters = 'Reporters',
  Member = 'Member',
  OfflineElectionOfficer = 'Offline Election Officer',
  BoothElectionOfficer = 'Booth Election Officer'
}