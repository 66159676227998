import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Role } from 'src/app/enums/member/role.enum';
import { AuthService } from 'src/app/services/auth/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem('pkrUser'));
  ROLE = Role;
  @Output() trigger: EventEmitter<null> = new EventEmitter();
  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }
}
