import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'voting',
    loadChildren: () =>
      import('./modules/voting/voting.module').then((m) => m.VotingModule),
  },

  {
    path: 'withdrawal',
    loadChildren: () =>
      import('./modules/withdrawal/withdrawal.module').then(
        (m) => m.WithdrawalModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./modules/messages/messages.module').then(
        (m) => m.MessagesModule
      ),
  },
  {
    path: 'voting',
    loadChildren: () =>
      import('./modules/voting/voting.module').then((m) => m.VotingModule),
  },
  {
    path: 'member',
    loadChildren: () =>
      import('./modules/member/member.module').then((m) => m.MemberModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'election',
    loadChildren: () =>
      import('./modules/election/election.module').then(
        (m) => m.ElectionModule
      ),
  },
  {
    path: 'results',
    loadChildren: () =>
      import('./modules/result/result.module').then((m) => m.ResultModule),
  },
  {
    path: 'nomination',
    loadChildren: () =>
      import('./modules/nomination/nomination.module').then(
        (m) => m.NominationModule
      ),
  },
  {
    path: 'objection',
    loadChildren: () =>
      import('./modules/objection/objection.module').then(
        (m) => m.ObjectionModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./modules/news/news.module').then(
        (m) => m.NewsModule
      ),
  },
  {
    path: 'report-list',
    loadChildren: () =>
      import('./modules/report-list/report-list.module').then(
        (m) => m.ReportListModule
      ),
  },
  {
    path: 'mpp',
    loadChildren: () =>
      import('./modules/senarai/senarai.module').then(
        (m) => m.SenaraiModule
      ),
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./modules/event-management/event-management.module').then((m) => m.EventManagementModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
