import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { delay } from 'rxjs/operators';
import { Role } from './enums/member/role.enum';
import { AuthService } from './services/auth/auth/auth.service';
import { CommonService } from './services/common/common.service';
import { LoadingSpinnerService } from './services/loading-spinner/loading-spinner.service';
import { MemberProfileService } from './services/member-profile/member-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hasRoleChanged$ = this.profileService.changeRole$;
  currentUser = JSON.parse(localStorage.getItem('pkrUser'));
  ROLE = Role;
  @ViewChild('drawer') public sidenav: MatSidenav;
  loading: boolean = false;
  sidenavWidth = 208;
  isMobile: boolean;
  screenWidth: number;
  constructor(
    public auth: AuthService,
    private loadingService: LoadingSpinnerService,
    private common: CommonService,
    private profileService: MemberProfileService
  ) {

    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    }
  }

  ngOnInit() {
    this.isMobile = this.common.isMobile;
    this.listenToLoading();
  }
  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  toggle(_drawer: any) {
  }
}
