<app-header *ngIf="auth.isLoggedin()" (trigger)="sidenav.toggle()"></app-header>

<mat-sidenav-container
  [hasBackdrop]="isMobile ? 'true' : 'false'"
  *ngIf="hasRoleChanged$ | async as currentUserRole"
  [ngClass]="{'height' : auth.isLoggedin()}"
>
  <mat-sidenav
    #drawer
    *ngIf="auth.isLoggedin() && currentUserRole !== ROLE.BoothElectionOfficer"
    role="navigation"
    [opened]="screenWidth > 767 ? true : false"
    [mode]="screenWidth > 767 ? 'side' : 'over'"
  >
    <app-side-nav-list></app-side-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <div id="page-content">
        <router-outlet></router-outlet>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-refresh *ngIf="loading"></app-refresh>
