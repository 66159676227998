export const MEMBER = {
    INPUT_IC: 'member/validateIc', //pass ic in query-param.
    LOGIN_WITH_OTP: 'member/loginWithOtp',
    REGISTER_MOBILE_NUMBER: 'member/registerMobileWithIc', // used when existing user wants to register their new mobile no.
    CHECK_IF_MOBILE_NO_ALREADY_EXISTS: 'member/validateMobileNumber', //pass mobile in query-param

    // send ic,mobile & name to receive an otp to complete first-time registration.
    FIRST_TIME_SEND_OTP: 'member/sendOtp',
    FIRST_TIME_REGISTER: 'member/register',

    // profile
    GET_DETAILS: 'member/',
    UPDATE_DETAILS: 'member/',
    LIST: 'member/list',
};

export const UTILITIES = {
    GET_STATES: 'common/states',
    GET_DIVISIONS: '',
    GET_SPECIALIZATIONS: 'common/specializations',
    GET_QUALIFICATIONS: 'common/qualifications',
    GET_BRANCHES: 'common/branches',
    GET_RACES: 'common/races',
    GET_ALL_STATES: 'common/allStates',
    GET_ALL_DIVISIONS: 'common/allDivisions',
};

export const AUTH = {
    LOGIN: 'user/login',
};

export const ELECTION = {
    CREATE_ELECTION: 'election/create',
    GET_LIST: 'election/list',
    GET_ELECTION_NOMINATION_LIST: 'election/',
    GET_NOMINATED_CANDIDATE_DETAILS: 'nomination/',
    CANDIDATE_APPROVE: 'nomination/',
    CANDIDATE_REJECT: 'nomination/',
    GET_RESULT: 'result/', // add election id in params.
    GET_STATS: 'election/', // add election id in params.
};

export const OBJECTION = {
    LIST: 'objection/list',
    DETAILS: 'objection/',
};

export const FILE = {
    UPLOAD_MEDIA: 'media/upload',
    DOWNLOAD_FILE: 'media/{0}/download/file',
    DELETE_FILE: 'media/',
};

export const BOOTH = {
    FIND_MEMBER_BY_IC: 'member/findByIc/', // add ic number in params.
    FIND_ALL_ELECTIONS_FOR_MEMBER: 'election/booth/eligibleElections/', // add ic number in params
    SUBMIT_VOTE: 'vote/registerViaBooth',
};

export const VOTING = {
    CREATE_VOTING: 'vote/registerViaBooth',
};

export const DASHBOARD = {
    GET_STATS: 'user/dashboardStats',
    GET_ONGOING_ELECTIONS: 'election/ongoingElections',
    GET_ONGOING_ELECTIONS_DETAIL: 'election/ongoingElections/',
};

export const USER = {
    GET_STATE_WISE_STATES: 'user/stateWiseStats/{id}',
    GET_LIST: 'user/list',
};

export const RESULT = {
    DOWNLOAD_ELECTION_NOMINATIONS: 'report/nomination', //add election id in params
    DOWNLOAD_REPORT: 'report/result',
    DOWNLOAD_FINAL_REPORT: 'report/final',
    DOWNLOAD_RESULT: 'report/result',
    DOWNLOAD_VOTER_LIST: 'report/voters', //add election id in params
    DOWNLOAD_UNDERFILLED_ELECTIONS: 'report/underfilledElectionRoleNominations',
    DOWNLOAD_REJECTED_VOTES_LIST: 'report/rejectedVotesList',
    DOWNLOAD_OVERALL_VOTING_STATS: 'report/overallVotingStats',
    DOWNLOAD_DIVISION_WISE_VOTING_STATS: 'report/divisionWiseVotingStats/', // add electionId in params.
    DOWNLOAD_NOMINATIONS: 'report/nominationsList',
    DOWNLOAD_OBJECTIONS: 'report/objectionsList',

    PUBLISH_RESULT: 'result/{electionId}/publishResult',
    PUBLISH_CENTRAL_CONSOLIDATED_RESULT:
        'result/{electionId}/publishCentralConsolidatedResult',
    UNPUBLISH_RESULT: 'result/{electionId}/unpublishResult',
};

export const NEWS = {
    NEWS_LIST: 'news/list',
    NEWS: 'news',
    NEWS_NOTIFICATION_COUNT: 'news/findMemberCountForNotification',
};

export const REPORT = {
    GET_PRIORITIES: 'reporting/mgmt/reportPriorities',
    GET_MANAGEMENT_USER_LIST: 'reporting/mgmt/usersList',
    GET_TYPES: 'reporting/mgmt/reportTypes',
    GET_LIST: 'reporting/mgmt/list',
    DOWNLOAD_EXCEL: 'reporting/mgmt/downloadExcel',
    GET_DETAIL: 'reporting/mgmt/{id}',
    SUBMIT_REPORT_TO_VALIDATION: 'reporting/mgmt/{id}/reportValidation',
    SUBMIT_REPORT_TO_AUTHORITY: 'reporting/mgmt/{id}/authorityReview',
    SUBMIT_REPORT_TO_INVESTIGATION: 'reporting/mgmt/{id}/investigation',
    SUBMIT_REPORT_TO_AUTHORIZE: 'reporting/mgmt/{id}/authorize',
    REJECT_REPORT: 'reporting/mgmt/{id}/reject',
    UPDATE_DETAIL_PRIORITY: 'reporting/mgmt/{id}/priority',
    GET_STATS: 'reporting/mgmt/dashboardStats',
};

export const EVENTS = {
    GET_LIST: 'event/list',
    CREATE_EVENT: 'event',
    PUBLISH_EVENT: 'event/{id}/publish',
    CANCEL_EVENT: 'event/{id}/cancel',
    GET_REGISTRATIONS: 'event/{id}/registrations',
    APPROVE_REGISTRATION: 'event/{id}/rsvp/{regId}/approve',
    REJECT_REGISTRATION: 'event/{id}/rsvp/{regId}/reject',
    DOWNLOAD_REGISTRATION_LIST: 'event/{id}/downloadRegistrationsList',
}