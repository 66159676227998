import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommonService } from '../common/common.service';
import * as apiConstants from 'src/utils/api-constants';
import { States } from 'src/app/interfaces/states/states.interface';
import { Divisions } from 'src/app/interfaces/divisions/divisions.interface';
import { Specialization } from 'src/app/interfaces/specialization/speciallization.interface';
import { Qualification } from 'src/app/interfaces/qualification/qualification.interface';
import { HttpParams } from '@angular/common/http';
import { Branches } from 'src/app/interfaces/branches/branches.interface';
import { pluck } from 'rxjs/operators';
import { MemberUpdate } from 'src/app/interfaces/member/member.update.interface';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/enums/member/role.enum';
import { FileTypePipe } from 'src/app/pipes/file-type.pipe';

@Injectable({
  providedIn: 'root',
})
export class MemberProfileService {
  changeRole$ = new BehaviorSubject<Role>(Role.HQ);
  constructor(
    private common: CommonService,
    private fileTypePipe: FileTypePipe,
  ) {}

  getMemberDetails(id: string): Observable<MemberUpdate> {
    return this.common.reactiveGet(apiConstants.MEMBER.GET_DETAILS + id);
  }

  getAllStates(limit: string, skip: string): Observable<States[]> {
    const params = new HttpParams().set('limit', limit).set('skip', skip);
    return this.common.reactiveGet(apiConstants.UTILITIES.GET_STATES, params);
  }

  getDivisionsByState(
    stateId: string,
    limit: string,
    skip: string
  ): Observable<Divisions[]> {
    const params = new HttpParams().set('limit', limit).set('skip', skip);
    return this.common.reactiveGet(
      `common/states/${stateId}/divisions`,
      params
    );
  }

  getAllSpecializations(
    limit: string,
    skip: string,
    searchString?: string
  ): Observable<Specialization[]> {
    let params = new HttpParams();
    if (limit) {
      params = params.append('limit', limit);
    }
    if (skip) {
      params = params.append('skip', skip);
    }
    if (searchString) {
      params = params.append('searchString', searchString);
    }
    return this.common.reactiveGet(
      apiConstants.UTILITIES.GET_SPECIALIZATIONS,
      params
    );
  }

  getAllQualifications(
    limit: string,
    skip: string,
    searchString?: string
  ): Observable<Qualification[]> {
    let params = new HttpParams();
    if (limit) {
      params = params.append('limit', limit);
    }
    if (skip) {
      params = params.append('skip', skip);
    }
    if (searchString) {
      params = params.append('searchString', searchString);
    }
    return this.common.reactiveGet(
      apiConstants.UTILITIES.GET_QUALIFICATIONS,
      params
    );
  }

  getBranchesByStateAndDivision(
    stateId: string,
    divisionId: string,
    limit: string,
    skip: string
  ): Observable<Branches[]> {
    const params = new HttpParams()
      .set('limit', limit)
      .set('skip', skip)
      .set('stateId', stateId)
      .set('divisionId', divisionId);
    return this.common.reactiveGet(apiConstants.UTILITIES.GET_BRANCHES, params);
  }

  getAllRaces(): Observable<string[]> {
    return this.common
      .reactiveGet(apiConstants.UTILITIES.GET_RACES)
      .pipe(pluck('races'));
  }

  updateMemberProfile(member: MemberUpdate): Observable<MemberUpdate> {
    return this.common.reactivePatch(
      apiConstants.MEMBER.UPDATE_DETAILS + member.id + '/modify',
      member
    );
  }

  async getFilePath(fileId: string) : Promise<string> {
    return new Promise((resolve, reject) => {
      this.fileTypePipe.transform(fileId, 'file').subscribe({
        next: (data) => resolve(data as string),
        error: (err) => reject(err),
      });
    });
  }
}
