import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'fileType',
})
export class FileTypePipe implements PipeTransform {
  constructor(private http: HttpClient) { }

  transform(id: string, type: string): Observable<SafeUrl> {
    let url = '';
    switch (type) {
      case 'file':
        url = id ? environment.imgUrl + id + '/sasurl/file' : '';
        break;
      case 'thumb':
        url = id ? environment.imgUrl + id + '/sasurl/thumb' : '';
        break;
      case 'video':
        url = id ? environment.imgUrl + id + '/sasurl/file' : '';
        break;
      default:
        break;
    }
    return this.http.get(url).pipe(map((val: any) => val.sasUrl));
  }
}