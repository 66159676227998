import { Component, OnInit } from '@angular/core';
const incr = 1;
@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent implements OnInit {
  progress = 0;
  constructor() {}

  ngOnInit(): void {
    setInterval(() => this.manageProgress(), 20);
  }

  manageProgress() {
    if (this.progress === 100) {
      this.progress = 100;
    } else {
      this.progress = this.progress + incr;
    }
  }
}
