import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss']
})

export class SideNavListComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem('pkrUser'));
  constructor() { }

  ngOnInit(): void {
  }

}





